import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import {HashRouter} from "react-router-dom";
import "./assets/base.scss";
import Main from "./www/Main";
import configureStore from "./config/configureStore";
import {Provider} from "react-redux";
import {Bounce, toast} from "react-toastify";

const store = configureStore();
const rootElement = document.getElementById("root");
global.ipServer = "https://jkrspeedsradar.com/be/"
// global.ipServer = "http://localhost:8080/"
// if (window.location.hostname !== "localhost" && !window.location.href.includes(":")) {
//     global.ipServer = window.location.protocol + "//" + window.location.hostname + "/troop-be/";
//     global.ipDetect = window.location.protocol + "//" + window.location.hostname + "/detect-be/";
//     global.trustbp = "https://trustbp.tm.com.my/"
//
// } else {
//     if (window.location.href.includes(":300")) { /*Logic SERVER OFFICE*/
//         global.ipServer = `http://${window.location.hostname}:8080/`;   /*Logic LOCALHOST*/
//         global.ipDetect = `http://${window.location.hostname}:8081/`;   /*detect*/
//         global.trustbp = "https://trustbp.tm.com.my/"
//     } else {
//         global.ipServer = window.location.protocol + "//" + window.location.hostname + "/troop-be/";  /*Logic LOCALHOST*/
//         global.ipDetect = window.location.protocol + "//" + window.location.hostname + "/detect-be/";  /*Logic LOCALHOST*/
//         global.trustbp = "https://trustbp.tm.com.my/"
//     }
// }
global.user_name = localStorage.getItem('user_name');
global.user_id = localStorage.getItem('user_id');
global.user_role = localStorage.getItem('user_role');


export const redirectLogout = (status) => {
    window.location.replace(`${window.location.origin}/#/login`)
};
export const toastFunc = (msg, type) => {
    toast(msg, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'top-right',
        type: type
    });
}
export const toastFuncManualClose = (msg, type) => {
    toast(msg, {
        transition: Bounce,
        closeButton: true,
        autoClose: false,
        position: 'top-right',
        type: type
    });
}

const renderApp = (Component) => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter>
                <Component/>
            </HashRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept("./www/Main", () => {
        const NextApp = require("./www/Main").default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();
