import {Link, Redirect, Route} from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";
import {redirectLogout} from '../../index'
import { ToastContainer } from "react-toastify";
import {Col} from "reactstrap";
const Settings = lazy(() => import("../../www/Setting"));
const User = lazy(() => import("../../www/User"));
const Dashboard = lazy(() => import("../../www/Dashboard"));
const Dashboard2 = lazy(() => import("../../www/Dashboard2"));
const UserProfile = lazy(() => import("../../www/UserProfile"));
const Proposal = lazy(() => import("../../www/Proposal"));
const ProposalApproval = lazy(() => import("../../www/ProposalApproval"));
const SyndicationApproval = lazy(() => import("../../www/SyndicationApproval"));
const Notification = lazy(() => import("../../www/Notification"));
const Login = lazy(() => import('../../www/auth'));
const Auth = lazy(() => import('../../www/auth'));
const URL = true
if (!URL) {
    document.onkeydown = function (e) {
        if (e.keyCode == 123) {
            console.log('You cannot inspect Element');
            return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
            console.log('You cannot inspect Element');
            return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
            console.log('You cannot inspect Element');
            return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
            console.log('You cannot inspect Element');
            return false;
        }
        if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
            console.log('You cannot inspect Element');
            return false;
        }
    }
// prevents right clicking
    document.addEventListener('contextmenu', e => e.preventDefault());
}

export const fakeAuth = {

    isAuthenticated: true,

    authenticate(cb, h) {
        try {

            if (this.isAuthenticated) {//clear untuk unauthorized

                localStorage.setItem("user_name", cb['user_name'])
                localStorage.setItem("user_id", cb['id'])
                localStorage.setItem("user_role", cb['role'])
                // let data = JSON.parse(atob(cb.split('.')[1]))
                // localStorage.setItem('troop_staff_id', data.staff_id);
                // localStorage.setItem('troop_staff_name', data.staff_name);
                // localStorage.setItem('troop_lob', data.lob);
                // localStorage.setItem('troop_lob_id', data.lob_id);
                // localStorage.setItem('troop_email', data.email);
                // localStorage.setItem('troop_phone_no', data.phone_no);
                // localStorage.setItem('troop_role', data.role);
                // localStorage.setItem('troop_is_admin', data.is_admin);
                // localStorage.setItem('troop_pers_no', data.pers_no);
                // localStorage.setItem('troop_division', data.division);
                // localStorage.setItem('troop_band', data.band);
                // localStorage.setItem('troop_cost_centre', data.cost_centre);
                // localStorage.setItem('troop_bcpra', data.bcpra);
                // localStorage.setItem('troop_cost_centre', data.region);

                h.push('/dashboard2');
                // alert()
                window['location']['reload']()

            }

        } catch (error) {
            // alert(error)
            window['location']['reload']()
            // redirectLogout(401)
        }


    },
    signout(cb) {
        localStorage.clear()
        // this.isAuthenticated = null
        redirectLogout(401)
    }
};


const PrivateRoute = ({component: Component, ...rest}) => {
    // alert()

    return (URL === true ? <Route {...rest} render={(props) => (
        fakeAuth.isAuthenticated !== null
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/login',
                state: {from: props.location}
            }}/>
    )}/> : <Route {...rest} render={(props) => (
        fakeAuth.isAuthenticated !== null
            ? <Component {...props} />
            : <Redirect to='/responseTrust'/>
    )}/>)
};

export const Page404 = () => (

    <div className="h-100 bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
            <Col md="8" className="mx-auto app-login-box">
                <div className="modal-dialog w-100 mx-auto">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="h5 modal-title text-center">
                                <div className="mt-2">
                                    <h2 style={{fontFamily: 'fantasy'}}>Error 404-Not Found</h2>
                                    {/*<h6>Mohon maaf, halaman yang Anda cari tidak ditemukan.*/}
                                    {/*    Kemungkinan halaman dihapus atau Anda salah menuliskan URL.*/}
                                    {/*</h6>*/}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer clearfix">
                            {/*<div className="float-left">*/}
                            {/*    <span>Kembali</span>*/}
                            {/*</div>*/}
                            <div className="float-right">
                                <Link className="mr-2 btn-icon btn-icon-only btn btn-primary" to={'/todolist'}><i
                                    className='lnr-home'/></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </div>
    </div>
);
// export const Page401 = () => (
//     <div className="h-100 bg-animation">
//         <div className="d-flex h-100 justify-content-center align-items-center">
//             <Col md="8" className="mx-auto app-login-box">
//                 <div className="modal-dialog w-100 mx-auto">
//                     <div className="modal-content">
//                         <div className="modal-body">
//                             <div className="h5 modal-title text-center">
//                                 <div className="mt-2">
//                                     <h2 style={{fontFamily: 'fantasy'}}>Error 401-Unauthorized</h2>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="modal-footer clearfix">
//                             <div className="float-right">
//                                 <Link className="mr-2 btn-icon btn-icon-only btn btn-primary"
//                                       onClick={redirectLogout(401)}><i
//                                     className='lnr-home'/></Link>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </Col>
//         </div>
//     </div>
// );

export const waitRestrUST = () => (
    <div className="h-100 bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
            <Col md="8" className="mx-auto app-login-box">
                <div className="modal-dialog w-100 mx-auto">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="h5 modal-title text-center">
                                <div className="mt-2">
                                    <h2 style={{fontFamily: 'fantasy'}}>Exit From TROOP</h2>
                                    <h2 style={{fontFamily: 'fantasy'}}>Wait Response From trUSt</h2>
                                    <h2 style={{fontFamily: 'fantasy'}}>Please Wait...</h2>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </div>
    </div>
);
const AppMain = () => {

    return (
        <Fragment>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">Please wait while we load all the Components</h6>
                    </div>
                </div>
            }>
                <Route path="/responseTrust" component={waitRestrUST}/>
            </Suspense>
            {/*<Route path="/401" component={Page401}/>*/}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">Please wait while we load all the Components</h6>
                    </div>
                </div>
            }>
                {URL === true && <Route path="/login" component={Login}/>}
            </Suspense>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">Please wait while we load all the Components</h6>
                    </div>
                </div>
            }>
                <Route path="/auth/:data" component={Auth}/>
            </Suspense>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">Please wait while we load all the Components</h6>
                    </div>
                </div>
            }>
                <Route path="/dashboard" component={Dashboard}/>
            </Suspense>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">Please wait while we load all the Components</h6>
                    </div>
                </div>
            }>
                <Route path="/dashboard2" component={Dashboard2}/>
            </Suspense>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">Please wait while we load all the Components</h6>
                    </div>
                </div>
            }>
                <PrivateRoute path="/setting" component={Settings}/>
            </Suspense>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">Please wait while we load all the Components</h6>
                    </div>
                </div>
            }>
                <PrivateRoute path="/notification" component={Notification}/>
            </Suspense>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">Please wait while we load all the Components</h6>
                    </div>
                </div>
            }>
                <PrivateRoute path="/user" component={UserProfile}/>
            </Suspense>


            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">Please wait while we load all the Components</h6>
                    </div>
                </div>
            }>
                <PrivateRoute path="/proposal" component={Proposal}/>
            </Suspense>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">Please wait while we load all the Components</h6>
                    </div>
                </div>
            }>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">Please wait while we load all the Components</h6>
                    </div>
                </div>
            }>
                <PrivateRoute path="/proposalapp" component={ProposalApproval}/>
            </Suspense>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">Please wait while we load all the Components</h6>
                    </div>
                </div>
            }>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">Please wait while we load all the Components</h6>
                    </div>
                </div>
            }>
                <PrivateRoute path="/syndicationapp" component={SyndicationApproval}/>
            </Suspense>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">Please wait while we load all the Components</h6>
                    </div>
                </div>
            }>
            </Suspense>



            <Route exact path="/" render={() => (
                <Redirect to="/login"/>
            )}/>
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;
